export const routes = {
  HOME: '/',
  NOT_FOUND: '/404',
  INTERNAL_SERVER_ERROR: '/500',

  AUTH: '/auth',
  LOST_PASSWORD: '/auth/lost-password',
  REGISTER_VERIFY: '/auth/verify',

  PRODUCTS: '/san-pham',
  PRODUCT: '/san-pham/[slug]',

  CART: '/cart',
  ORDER_COMPLETE: '/order-complete',

  BLOGS: '/blogs',
  BLOG: '/blogs/[slug]',

  CONTACT: '/lien-he',
  ABOUT: '/gioi-thieu',
  DISTRIBUTION: '/he-thong-phan-phoi',

  CHECKOUT: '/thanh-toan',
  FAQ: '/cau-hoi-thuong-gap',
  PRIVACY_POLICY: '/chinh-sach/bao-mat',
  RETURN_POLICY: '/chinh-sach/doi-tra',

  MY_ACCOUNT: '/my-account',
  MY_ACCOUNT_ADDRESSES: '/my-account/addresses',
  MY_ACCOUNT_ORDERS: '/my-account/orders',
  MY_ACCOUNT_ORDER: '/my-account/orders/[id]',
  MY_ACCOUNT_WISHLIST: '/my-account/wishlist',
  MY_ACCOUNT_PROFILE: '/my-account/edit-account',
  MY_ACCOUNT_CHANGE_PASSWORD: '/my-account/change-password',
} as const;
